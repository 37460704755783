/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var slick = $('.slick');
        slick.slick({
          autoplay: true,
          autoplaySpeed: 9000,
          fade: true,
          arrows: true,
          infinite: true
        });

        // running line init
        function initMarquee() {
          jQuery('.our-stroies').marquee({
            line: '.stroies-block',
            animSpeed: 50
          });
        }
        /*
         * jQuery <marquee> plugin
         */
        function Marquee(options) {
          this.options = $.extend({
            holder: null,
            handleFlexible: true,
            pauseOnHover: true,
            hoverClass: 'hover',
            direction: 'left',
            cloneClass: 'cloned',
            mask: null,
            line: '>*',
            items: '>*',
            animSpeed: 10, // px per second
            initialDelay: 0
          }, options);
          this.init();
        }
        Marquee.prototype = {
          init: function() {
            if(this.options.holder) {
              this.initStructure();
              this.attachEvents();
            }
          },
          initStructure: function() {
            // find elements
            this.holder = $(this.options.holder);
            this.mask = this.options.mask ? this.holder.find(this.options.mask) : this.holder,
              this.line = this.mask.find(this.options.line),
              this.items = this.line.find(this.options.items).css({'float':'left'});
            this.direction = (this.options.direction === 'left') ? -1 : 1;
            this.recalculateDimensions();
            // prepare structure
            this.cloneItems = this.items.clone().addClass(this.options.cloneClass).appendTo(this.line);
            if(this.itemWidth >= this.maskWidth) {
              this.activeLine = true;
              this.offset = (this.direction === -1 ? 0 : this.maxOffset);
            } else {
              this.activeLine = false;
              this.cloneItems.hide();
              this.offset = 0;
            }
            this.line.css({
              width: this.itemWidth * 2,
              marginLeft: this.offset
            });
          },
          attachEvents: function() {
            // flexible layout handling
            var self = this;
            if(this.options.handleFlexible) {
              this.resizeHandler = function() {
                self.recalculateDimensions();
                if(self.itemWidth < self.maskWidth) {
                  self.activeLine = false;
                  self.cloneItems.hide();
                  self.stopMoving();
                  self.offset = 0;
                  self.line.css({marginLeft: self.offset});
                } else {
                  self.activeLine = true;
                  self.cloneItems.show();
                  self.startMoving();
                }
              };
              $(window).bind('resize orientationchange', this.resizeHandler);
            }
            // pause on hover
            if(this.options.pauseOnHover) {
              this.hoverHandler = function() {
                self.stopMoving();
                self.holder.addClass(self.options.hoverClass);
              };
              this.leaveHandler = function() {
                self.startMoving();
                self.holder.removeClass(self.options.hoverClass);
              };
              this.holder.bind({ mouseenter: this.hoverHandler, mouseleave: this.leaveHandler });
            }
            // initial delay
            setTimeout(function(){
              self.initialFlag = true;
              self.startMoving();
            }, self.options.initialDelay || 1);
          },
          recalculateDimensions: function() {
            // calculate block dimensions
            var self = this;
            this.maskWidth = this.mask.width();
            this.itemWidth = 1;
            this.items.each(function(){
              self.itemWidth += $(this).outerWidth(true);
            });
            this.maxOffset = -this.itemWidth;
          },
          startMoving: function() {
            // start animation
            var self = this;
            if(self.activeLine && self.initialFlag) {
              var targetOffset = (self.direction < 0 ? self.maxOffset : 0);
              self.offset = parseInt(self.line.css('marginLeft'), 10) || 0;
              self.line.stop().animate({
                marginLeft: targetOffset
              }, {
                duration: Math.abs(1000 * (self.offset - targetOffset) / self.options.animSpeed),
                easing: 'linear',
                complete: function() {
                  self.offset = (self.direction < 0 ? 0 : self.maxOffset);
                  self.line.css({marginLeft: self.offset});
                  self.startMoving();
                }
              });
            }
          },
          stopMoving: function() {
            // stop animation
            this.line.stop();
          },
          destroy: function() {
            this.stopMoving();
            this.cloneItems.remove();
            this.items.css({'float':''});
            this.line.css({marginLeft:'',width:''});
            this.holder.removeClass(this.options.hoverClass);
            this.holder.unbind('mouseenter', this.hoverHandler);
            this.holder.unbind('mouseleave', this.leaveHandler);
            $(window).unbind('resize orientationchange', this.resizeHandler);
          }
        };
        // jQuery plugin interface
        $.fn.marquee = function(opt) {
          return this.each(function() {
            jQuery(this).data('Marquee', new Marquee($.extend(opt, {holder: this})));
          });
        };


        initMarquee();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        var roadAtlasStyles = [
          {
            'featureType': 'road.highway',
            'elementType': 'geometry',
            'stylers': [
              {'saturation': -100},
              {'lightness': -8},
              {'gamma': 1.18}
            ]
          },
          {
            'featureType': 'road.arterial',
            'elementType': 'geometry',
            'stylers': [
              {'saturation': -100},
              {'gamma': 1},
              {'lightness': -24}
            ]
          },
          {
            'featureType': 'poi',
            'elementType': 'geometry',
            'stylers': [
              {'saturation': -100}
            ]
          },
          {
            'featureType': 'administrative',
            'stylers': [
              {'saturation': -100}
            ]
          },
          {
            'featureType': 'transit',
            'stylers': [
              {'saturation': -100}
            ]
          },
          {
            'featureType': 'water',
            'elementType': 'geometry.fill',
            'stylers': [
              {'saturation': -100},
              {'lightness': -20}
            ]
          },
          {
            'featureType': 'road',
            'stylers': [
              {'saturation': -100}
            ]
          },
          {
            'featureType': 'administrative',
            'stylers': [
              {'saturation': -100}
            ]
          },
          {
            'featureType': 'landscape',
            'stylers': [
              {'saturation': -100},
              {'lightness': -5}
            ]
          },
          {
            'featureType': 'poi',
            'stylers': [
              {'saturation': -100}
            ]
          }
        ];


        var map = new GMaps({
          el: '#map',
          lat: 30.262997,
          lng: -97.784801
        });

        map.setOptions(
          {
            'styles': roadAtlasStyles,
            scrollwheel: false,
            scaleControl: false,
            rotateControl: false,
            panControl: true,
            overviewMapControl: false,
            mapTypeControl: false,
            draggable: true,
            disableDefaultUI: true,
            zoomControl: true,
            streetViewControl: false

          }
        );

        function pinSymbol(color) {
          return {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
            fillColor: color,
            fillOpacity: 1,
            strokeColor: '#5b6f9b',
            strokeWeight: 2,
            scale: 1
          };
        }

        map.addMarker({
          lat: 30.262997,
          lng: -97.784801,
          title: 'MSMWO',
          icon: '/wp-content/uploads/2016/10/logofinal-blue-e1477583871545.png',
          infoWindow: {
            content: '<div><b>McElroy, Sullivan, Miller, Weber & Olmstead, L.L.P.</b><br />1201 Spyglass Drive, Suite 200<br />Austin, TX 78746<br /><br />T: 512.327.8111<br />F: 512.327.6566<br /><a href="https://www.google.com/maps/dir//McElroy+Sullivan+Ryan+Miller:+Tough+Paul+R,+1201+Spyglass+Dr+%23200,+Austin,+TX+78746/@30.2634818,-97.7866738,17z/data=!4m15!1m6!3m5!1s0x865b4acd354e8e41:0x9f7dc62c2c27eb35!2sMcElroy+Sullivan+Ryan+Miller:+Tough+Paul+R!8m2!3d30.2634818!4d-97.7844851!4m7!1m0!1m5!1m1!1s0x865b4acd354e8e41:0x9f7dc62c2c27eb35!2m2!1d-97.7844851!2d30.2634818" target="_blank">Get Directions</a></div>'
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
